import { RequestPriorityDeliveryApiRepository } from "@/app/infrastructures/repositories/api/RequestPriorityDeliveryApiRepository";
import { RequestPriorityDeliveryInterface } from "@/data/payload/contracts/RequestPriorityDelivery";
import { RequestPriorityDelivery } from "@/domain/entities/RequestPriorityDelivery";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class RequestPriorityDeliveryPresenter {
    private repo: RequestPriorityDeliveryApiRepository;

    constructor(repo: RequestPriorityDeliveryApiRepository) {
        this.repo = repo;
    }

    public createRpd(payload: RequestPriorityDeliveryInterface): Promise<ResponsePayload> {
        return this.repo.createRpd(payload);
    }
    public getDetailRpd(id: any): Promise<RequestPriorityDelivery> {
        return this.repo.getDetailRpd(id);
    }
}