import { RequestPriorityDeliveryPresenter } from "@/app/ui/presenters/RequestPriorityDeliveryPresenter";
import { RequestPriorityDeliveryMapper } from "@/data/persistences/mappers/RequestPriorityDeliveryMapper";
import { container } from "tsyringe";
import { RequestPriorityDeliveryEndpoint } from "../../endpoints/hydra/RequestPriorityDeliveryEndpoint";
import { RequestPriorityDeliveryApiRepository } from "../../repositories/api/RequestPriorityDeliveryApiRepository";
import ApiService from "../../services/ApiService";

export class RequestPriorityDeliveryComponent {
  public static init() {
    container.register<RequestPriorityDeliveryApiRepository>(RequestPriorityDeliveryApiRepository, {
      useFactory: d => {
        return new RequestPriorityDeliveryApiRepository(
          d.resolve(ApiService),
          d.resolve(RequestPriorityDeliveryMapper),
          d.resolve(RequestPriorityDeliveryEndpoint)
        );
      }
    });
    container.register<RequestPriorityDeliveryMapper>(RequestPriorityDeliveryMapper, {
      useClass: RequestPriorityDeliveryMapper
    });
    container.register<RequestPriorityDeliveryPresenter>(RequestPriorityDeliveryPresenter, {
      useFactory: d => {
        return new RequestPriorityDeliveryPresenter(d.resolve(RequestPriorityDeliveryApiRepository));
      }
    });
  }
}
